import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation();
  const [pathname] = useState(location.pathname);
  return (
    <div className="footer-nav-area" id="footerNav">
      <div className="container px-0">
        <div className="footer-nav position-relative">
          <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
            <li className={pathname === "/" ||pathname === "/beranda" ? "active" : ""}>
              <Link to="/beranda">
                <i className="bi bi-house"></i>
                <span>Beranda</span>
              </Link>
            </li>

            <li className={pathname === "/suara" || pathname === "/suara/detail" ? "active" : ""}>
              <Link to={"/suara"}>
                <i className="bi bi-collection"></i>
                <span>Suara</span>
              </Link>
            </li>

            <li className={pathname === "/laporan" ? "active" : ""}>
              <Link to="/laporan">
                <i className="bi bi-file-text"></i>
                <span>Laporan</span>
              </Link>
            </li>

            <li className={pathname === "/profil" ? "active" : ""}>
              <Link to="/profil">
                <i className="bi bi-person"></i>
                <span>Profil</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer