import React, { useCallback, useEffect, useState } from 'react'
import Header from '../../layouts/Header'
import { Card, ListGroup } from 'react-bootstrap'
import Footer from '../../layouts/Footer'
import { useStateAuthContext, useStateHostContext } from '../../context'
import { notif_error } from '../../notif'
import { HashLoader } from 'react-spinners'
import { formatDate } from '../../utils'

const Laporan = () => {
  const { HOST } = useStateHostContext()
  const { STATE_AUTH } = useStateAuthContext()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const handleGetFinalisasiTps = useCallback(async () => {
    setLoading(true)
    try {
      const requ = await fetch(`${HOST}/api/v1/suara/perhitungan/finalisasi/tps/${STATE_AUTH?.id_tps}/${STATE_AUTH?.id_kec}`, {
        method: 'GET'
      })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          setData(resu.data)
          setLoading(false)
        }
      } else {
        throw new Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }, [HOST, STATE_AUTH])

  console.log(data)

  useEffect(() => {
    handleGetFinalisasiTps()
  }, [handleGetFinalisasiTps])

  return (
    <>
      <Header menuUtama={true} judulHalaman={'Laporan'} handleBackWithParam={() => { return window.history.back() }} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <Card className='p-3 mb-2'>
            <h3>Laporan Finalisasi Pemilihan</h3>
          </Card>

          {
            !loading
              ?

              data.map((item, index) => {
                return (
                  <Card className="mb-2" key={index}>
                    <Card.Header>
                      <small style={{ fontSize: '18px', color: '#333' }}>PEMILIHAN {item.jenis}</small>
                    </Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item className='d-flex justify-content-between'>
                        <span className='text-muted'>Total Suara SAH</span>
                        <small style={{ fontSize: '14px' }}>{item.sah ? item.sah : 0}</small>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between'>
                        <span className='text-muted'>Total Suara Tidak SAH</span>
                        <small style={{ fontSize: '14px' }}>{item.tidak_sah ? item.tidak_sah : 0}</small>
                      </ListGroup.Item>
                      <ListGroup.Item className='d-flex justify-content-between'>
                        <span className='text-muted'>Status</span>
                        {
                          item.status_finalisasi
                            ?
                            <span className='badge bg-success rounded-pill' style={{ fontSize: '14px' }}>Selesai</span>
                            :
                            <span className='badge bg-danger rounded-pill' style={{ fontSize: '14px' }}>Belum Selesai</span>
                        }
                      </ListGroup.Item>
                      {
                        item.waktu_finalisasi
                          ?
                          <ListGroup.Item className='d-flex justify-content-between'>
                            <span className='text-muted'>Tanggal Selesai</span>
                            <small style={{ fontSize: '14px' }}>{formatDate(item.dibuat_pada)}</small>
                          </ListGroup.Item>
                          :
                          null
                      }
                    </ListGroup>
                  </Card>
                )
              })


              :
              <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
                <HashLoader />
              </div>
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Laporan