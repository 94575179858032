import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = ({ menuUtama, judulHalaman = null, linkBack = null, handleBackWithParam = null }) => {
  const direct = useNavigate()
  return (
    menuUtama
      ?
      <div class="header-area" id="headerArea">
        <div class="container">
          <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
            <div class="logo-wrapper">
              <a href="home.html">
                <img src={require('../assets/img/core-img/logo-smartrc.png')} alt="logo" width={"500px"} />
              </a>
            </div>
            <i className='bi bi-info-circle' style={{ fontSize: 24, cursor: 'pointer' }} onClick={() => direct('/versi')} />
          </div>
        </div>
      </div>
      :
      <div class="header-area" id="headerArea">
        <div class="container">
          <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
            <div class="back-button" onClick={handleBackWithParam}>
              <Link to={linkBack}>
                <i class="bi bi-arrow-left-short"></i>
              </Link>
            </div>

            <div class="page-heading">
              <h6 class="mb-0">{judulHalaman}</h6>
            </div>

            <span></span>
          </div>
        </div>
      </div>


  )
}

export default Header