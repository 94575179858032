import React from "react";
/**
 * Context from AuthReducer
 */
export const InitData = {
  id_relawan: null,
  id_tps: null,
  nik: false,
  nama: null,
  notelp: null,
  status: null,
  status_input_tps: null,
  foto: null,
  token: null,
  username: null,
  nama_tps: null,
  id_kel: null,
  dpt:null,
  dptb: null,
  dpk:null,
  id_kec: null,
  kel: null,
  kec: null,
};
export const AuthContext = React.createContext();

export const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      const data = {
        ...state,
        is_login: true,
        id_relawan: action.data.id_relawan,
        id_tps: action.data.id_tps,
        nik: action.data.nik,
        nama: action.data.nama,
        notelp: action.data.notelp,
        status: action.data.status,
        status_input_tps: action.data.status_input_tps,
        foto: action.data.foto,
        token: action.data.token,
        username: action.data.username,
        nama_tps: action.data.nama_tps,
        id_kel: action.data.id_kel,
        dpt: action.data.dpt,
        dptb: action.data.dptb,
        dpk: action.data.dpk,
        id_kec: action.data.id_kec,
        kel: action.data.kel,
        kec: action.data.kec,
      };
      localStorage.setItem('_SMART_RC_MOBILE_', JSON.stringify(data))
      return data;
    case "LOGOUT":
      localStorage.removeItem('_SMART_RC_MOBILE_');
      return {
        is_login: false,
        id_relawan: null,
        id_tps: null,
        nik: false,
        nama: null,
        notelp: null,
        status: null,
        status_input_tps: null,
        foto: null,
        token: null,
        username: null,
        nama_tps: null,
        id_kel: null,
        dpt:null,
        dptb: null,
        dptk: null,
        id_kec: null,
        kel: null,
        kec: null,
      }
    default:
      break;
  }
}

export const AuthProvider = ({ children }) => {
  const [STATE_AUTH, DISPATCH_AUTH] = React.useReducer(AuthReducer, InitData);
  return (
    <AuthContext.Provider value={{ STATE_AUTH, DISPATCH_AUTH }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useStateAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(
      "useStateAuthContext must be used within a StateAuthProvider"
    );
  }
  return context;
};

/**
 * Context from HostContext
 */
// const HOST = "https://smartrc.kotamobagu.go.id/webservices";
// const HOST = "http://localhost:8080"
// const HOST = "https://0c51-103-10-65-121.ngrok-free.app";
const HOST = "/webservices"
export const HostContext = React.createContext();
export const HostProvider = ({ children }) => {
  return (
    <HostContext.Provider value={{ HOST: HOST }}>
      {children}
    </HostContext.Provider>
  );
};
export const useStateHostContext = () => {
  const context = React.useContext(HostContext);
  if (!context) {
    throw new Error(
      "useStateHostContext must be used within a HostProvider"
    );
  }
  return context;
};