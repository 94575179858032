import React, { useState } from 'react'
import { Button, Card, Container, Form, ListGroup, Offcanvas } from 'react-bootstrap'
import { useStateAuthContext, useStateHostContext } from '../../context'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import { useNavigate } from 'react-router-dom'
import { notif_error, notif_success } from '../../notif'

const Profil = () => {
  const navigate = useNavigate()
  const { HOST } = useStateHostContext()
  const { STATE_AUTH, DISPATCH_AUTH } = useStateAuthContext()
  const [sandiBaru, setSandiBaru] = useState(null)
  const [showFormSandi, setShowFormSandi] = useState(false)
  const [showFormKeluar, setShowFormKeluar] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getDapil = (idKec) => {
    switch (idKec) {
      case "1":
        return 'Dapil Kotamobagu Barat'
      case "2":
        return 'Dapil Kotamobagu Utara'
      case "3":
        return 'Dapil Kotamobagu Timur'
      case "4":
        return 'Dapil Kotamobagu Selatan'
      default:
        break;
    }
  }

  const handleShowFormSandi = () => {
    setShowFormKeluar(false)
    setShowFormSandi(true)
    handleShow()
  }

  const handleShowFormKeluar = () => {
    setShowFormSandi(false)
    setShowFormKeluar(true)
    handleShow()
  }

  const handleUbahSandi = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('id_relawan', STATE_AUTH?.id_relawan)
    data.append('sandi', sandiBaru)
    try {
      const requ = await fetch(`${HOST}/api/v1/relawan/ubah-sandi`, {
        method: 'POST',
        body: data
      })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          notif_success({ msg: resu.message })
          handleClose()
          setSandiBaru(null)
        } else {
          notif_error({ msg: resu.message })
          handleClose()
        }
      } else {
        throw new Error({ msg: resu.message })
      }
    } catch (err) {
      notif_error(err.message)
    }
  }

  const handleKeluar = () => {
    DISPATCH_AUTH({ type: 'LOGOUT' })
    navigate('/')
  }
  return (
    <>
      <Header menuUtama={true} judulHalaman={'Profil'} handleBackWithParam={() => { return window.history.back() }} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="card  mb-3  bg-img  bg-overlay" style={{ backgroundImage: `url(${require('../../assets/img/core-img/bg-hero.png')})` }}>
            <div className="card-body d-flex align-items-center flex-column">
              <div className="user-profile me-3" style={{ width: '100px', height: '100px' }}>
                <img
                  src={`${HOST}/${STATE_AUTH?.foto}`}
                  alt=""
                  style={{
                    border: '1px solid #666',
                    padding: '2px',
                    borderRadius: '50%',
                    width: '100px',
                    height: '100px'
                  }}
                />
              </div>
              <div className="user-info text-center text-white">
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 text-white text-center">{STATE_AUTH?.nama}</h5>
                </div>
                <p className="mb-0 text-white">No Telp #{STATE_AUTH?.notelp}</p>
              </div>
            </div>
          </div>

          <Card className="user-data-card mb-2">
            <Card.Body className='d-flex flex-row align-items-center justify-content-between'>
              <div className='d-flex flex-column align-items-center text-center flex-fill'>
                <span style={{ fontSize: '28px' }}>{STATE_AUTH?.dpt}</span>
                <small className='text-muted'>TOTAL DPT</small>
              </div>
              <div className='d-flex flex-column align-items-center text-center flex-fill border-start'>
                <span style={{ fontSize: '28px' }}>{STATE_AUTH?.dptb}</span>
                <small className='text-muted'>TOTAL DPTB</small>
              </div>
              <div className='d-flex flex-column align-items-center text-center flex-fill border-start'>
                <span style={{ fontSize: '28px' }}>{STATE_AUTH?.dpk}</span>
                <small className='text-muted'>TOTAL DPK</small>
              </div>
            </Card.Body>
          </Card>

          <Card className="user-data-card mb-2">
            <ListGroup variant="flush">
              <ListGroup.Item className='d-flex flex-column'>
                <span className='text-muted'>TPS</span>
                <small style={{ fontSize: '16px' }}>{STATE_AUTH.nama_tps}</small>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex  flex-column'>
                <span className='text-muted'>KELURAHAN/DESA</span>
                <small style={{ fontSize: '16px' }}>{STATE_AUTH.kel}</small>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex  flex-column'>
                <span className='text-muted'>KECAMATAN</span>
                <small style={{ fontSize: '16px' }}>{STATE_AUTH.kec}</small>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex  flex-column'>
                <span className='text-muted'>DAPIL</span>
                <small style={{ fontSize: '16px' }}>{getDapil(STATE_AUTH.id_kec)}</small>
              </ListGroup.Item>

            </ListGroup>
          </Card>

          <Card className="user-data-card mb-2">
            <Button className='w-100 btn btn-lg btn-info' onClick={handleShowFormSandi}>Ubah Password</Button>
          </Card>

          <Card className="user-data-card mb-2">
            <Button className='w-100 btn btn-lg btn-danger' onClick={handleShowFormKeluar}>Keluar</Button>
          </Card>

          <Card>
            <Offcanvas show={show} onHide={handleClose} placement='bottom'>
              <Offcanvas.Body className='pb-3 text-center'>
                <Container>
                  {
                    showFormSandi && (
                      <div className='text-center border p-2'>
                        <i class="bi bi-key" style={{ fontSize: 32 }}></i>
                        <Form onSubmit={handleUbahSandi}>
                          <Form.Group>
                            <Form.Control size='lg' type='password' className='border border-warning' placeholder='Masukan Sandi Baru' value={sandiBaru} onChange={(e) => setSandiBaru(e.target.value)} />
                            <div className='d-flex flex-row justify-content-between'>
                              <Button className='btn btn-danger my-3 ' style={{ width: "150px" }} type='button' onClick={handleClose}>Batal</Button>
                              <Button className='btn btn-info my-3 ' style={{ width: "150px" }} type='submit'>Simpan</Button>
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    )
                  }

                  {
                    showFormKeluar && (
                      <div className='text-center border p-2'>
                        <i class="bi bi-box-arrow-in-left" style={{ fontSize: 32 }}></i> <br />
                        <span className='text-center'>Yakin ingin keluar? </span>
                        <div className='d-flex flex-row justify-content-between'>
                          <Button className='btn btn-info my-3' style={{ width: "150px" }} type='button' onClick={handleClose}>Tidak</Button>
                          <Button className='btn btn-danger my-3' style={{ width: "150px" }} type='button' onClick={handleKeluar}>Ya</Button>
                        </div>
                      </div>
                    )
                  }
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
          </Card>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profil