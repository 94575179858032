import React, { useEffect } from "react";
import Router from './routers';
import { useStateAuthContext } from "./context";

const App = () => {
  const { DISPATCH_AUTH } = useStateAuthContext()
  /**
   * 
   * Jika Sudah Login dan Belum keluar, maka akan tetap masuk pada halaman admin
   */
  useEffect(() => {
    const dataLocal = localStorage.getItem("_SMART_RC_MOBILE_")
    if (dataLocal) {
      const restoreData = JSON.parse(dataLocal)
      DISPATCH_AUTH({ type: 'LOGIN', data: restoreData })
    }
  }, [DISPATCH_AUTH])
  return (
    <Router />
  )
}

export default App;