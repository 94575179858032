import React from 'react'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import { useStateAuthContext } from '../../context'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const direct = useNavigate()
  const { STATE_AUTH } = useStateAuthContext()
  return (
    <>
      <Header menuUtama={true} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="card">
            <div className="card-body p-5 d-flex align-items-center justify-content-center flex-column">
              <img className="mb-4" src={require('../../assets/img/core-img/logo-smartrc.png')} alt="logo" width={250} />
              PWA Mobile Smart RC
              <small>versi 1.0</small>
              <hr />
              <p className='border-bottom'>Sistem ini digunakan untuk penginputan perolehan suara caleg dan capres per TPS se kota kotamobagu.</p>
              <hr />
              <small>
                Develop system by : Bobi R. Simbala
                contact : 082195100894
              </small>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Index