import React, { useState } from 'react'
import { useStateAuthContext, useStateHostContext } from '../../../context'
import { useLocation } from 'react-router-dom'
import Header from '../../../layouts/Header'
import { Button, Form } from 'react-bootstrap'
import { notif_error, notif_success } from '../../../notif'
import { HashLoader } from 'react-spinners'

const Index = () => {
  const { STATE_AUTH } = useStateAuthContext()
  const { HOST } = useStateHostContext()
  const location = useLocation()
  const [param] = useState(location.state)
  const [loading, setLoading] = useState(false)
  const [tps] = useState(STATE_AUTH?.id_tps)
  const [totalSuara, setTotalSuara] = useState(param?.totalSuara !== "0" ? param?.totalSuara : null)
  const [idCaleg] = useState(param?.idCaleg)
  const [idRelawan] = useState(STATE_AUTH?.id_relawan)
  // const [idPartai] = useState(param?.idPartai)
  const [idJenisPemilihan] = useState(param?.id)
  const [tglInput, setTglInput] = useState(param?.tglInput)

  const colorLoadingSet = (idJenis) => {
    switch (idJenis) {
      case 1:
        return "#555555"
      case 2:
        return "red"
      case 3:
        return "#a4d100"
      case 4:
        return "blue"
      case 5:
        return "green"
      default:
        break;
    }
  }
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    const data = new FormData()
    data.append('id_relawan', idRelawan)
    data.append('id_tps_relawan', tps)
    data.append('id_caleg', idCaleg)
    data.append('id_jenis_pemilihan', idJenisPemilihan)
    data.append('total', totalSuara)
    try {
      const requ = await (fetch(`${HOST}/api/v1/relawan/save-suara`, {
        method: 'POST',
        body: data
      }))
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          notif_success({ msg: resu.message })
          setLoading(false)
          setTglInput(true)
        } else {
          notif_error({ msg: resu.message })
          setLoading(false)
        }
      } else {
        throw new Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }

  // const handleSubmitSuaraPartai = async (e) => {
  //   setLoading(true)
  //   e.preventDefault()
  //   const data = new FormData()
  //   data.append('id_tps_relawan', tps)
  //   data.append('id_partai', idPartai)
  //   data.append('id_jenis_pemilihan', idJenisPemilihan)
  //   data.append('total', totalSuara)
  //   try {
  //     const requ = await (fetch(`${HOST}/api/v1/relawan/save-suara-partai`, {
  //       method: 'POST',
  //       body: data
  //     }))
  //     const resu = await requ.json()
  //     if (requ.ok) {
  //       if (resu.success) {
  //         notif_success({ msg: resu.message })
  //         setLoading(false)
  //         setTglInput(true)
  //       } else {
  //         notif_error({ msg: resu.message })
  //         setLoading(false)
  //       }
  //     } else {
  //       throw new Error(resu.message)
  //     }
  //   } catch (err) {
  //     notif_error({ msg: err.message })
  //     setLoading(false)
  //   }
  // }

  const handleReset = async (e) => {
    setLoading(true)
    e.preventDefault()
    const data = new FormData()
    data.append('id_tps_relawan', tps)
    data.append('id_caleg', idCaleg)
    try {
      const requ = await (fetch(`${HOST}/api/v1/relawan/reset-suara`, {
        method: 'POST',
        body: data
      }))
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          notif_success({ msg: resu.message })
          setLoading(false)
          setTglInput(false)
        } else {
          notif_error({ msg: resu.message })
          setLoading(false)
        }
      } else {
        throw new Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }

  return (
    <>
      <Header menuUtama={false} judulHalaman={'Input Suara'} handleBackWithParam={() => { return window.history.back() }} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="card  mb-3  bg-img  bg-overlay" style={{ backgroundImage: `url(${require(`../../../assets/img/core-pemilu/${param?.img}`)})` }}>
            <div className="card-body d-flex align-items-center flex-column">
              <div className="user-profile me-3" style={{ width: '100px', height: '100px' }}>
                <img
                  className='img-circle'
                  src={`${HOST}/${param?.foto}`}
                  alt=""
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    border: '1px solid #666',
                    padding: '2px',
                    width: '100px', // Memastikan gambar mengisi ruang lingkaran
                    height: '100px' // Menyesuaikan ketinggian sesuai dengan lebar untuk menjaga proporsi
                  }}
                />
              </div>
              <div className="user-info text-center text-white">
                <div className="d-flex align-items-center">
                  <h5 className="mb-1 text-white text-center">{param?.namaCaleg}</h5>
                </div>
                <p className="mb-0 text-white">Nomor Urut #{param?.noUrut === "100" ? param?.noUrutPartai : param?.noUrut}</p>
              </div>
            </div>
          </div>

          <div className="card user-data-card">
            <div className="card-body">
            
              {
                tglInput
                  ?
                  <Form onSubmit={handleReset}>
                    <Form.Group className="mb-3">
                      <Form.Label className="form-label" htmlFor="Username">Total Suara</Form.Label>
                      <Form.Control className="form-control" type="number" value={totalSuara} placeholder="0" onChange={e => setTotalSuara(e.target.value)} inputMode="numeric" readOnly />
                    </Form.Group>
                    <Button className="btn btn-danger w-100" type="submit" disabled={loading}>Reset Data</Button>
                  </Form>
                  :
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label className="form-label" htmlFor="Username">Total Suara</Form.Label>
                      <Form.Control className="form-control" type="number" value={totalSuara} placeholder="0" onChange={e => setTotalSuara(e.target.value)} inputMode="numeric" />
                    </Form.Group>
                    <Button className="btn btn-success w-100" type="submit" disabled={loading}>Simpan Data</Button>
                  </Form>
              }

            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center mt-5">
            {loading ? <HashLoader size={64} className='mt-5' color={colorLoadingSet(param.id)} /> : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default Index