import React from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const Nopage = () => {
  const direct = useNavigate()
  return (
    <div class="page-content-wrapper">
      <div class="custom-container">
        <div class="card">
          <div class="card-body px-5 text-center">
            <img class="mb-4" src={require('../../assets/img/bg-img/39.png')} alt="" />
              <h4>OOPS... <br /> Laman tidak ditemukan!</h4>
              <p class="mb-4">Kami tidak menemukan halaman yang anda cari, silahkan coba kembali</p>
              <Button class="btn btn-creative btn-danger" onClick={()=>{direct('/')}} >Kembali</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nopage