import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider, HostProvider } from './context';
import { NotifContent } from './notif';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <HostProvider>
    <AuthProvider>
      <Router>
        <App />
        <NotifContent />
      </Router>
    </AuthProvider>
  </HostProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
