import React from 'react'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import { useStateAuthContext } from '../../context'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const direct = useNavigate()
  const {STATE_AUTH} = useStateAuthContext()
return (
    <>
      <Header menuUtama={true} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <div className="card">
            <div className="card-body p-5">
              <img className="mb-4" src={require('../../assets/img/bg-img/19.png')} alt="logo" />
              <h2 className="mb-4">Selamat datang, {STATE_AUTH?.nama}</h2>
              <small>Aplikasi ini digunakan untuk penginputan perolehan suara pemilu 2024</small> 
              <hr />
              <button className="btn btn-creative btn-warning btn-lg my-2 me-2 w-100" href="#" onClick={() => direct('/suara')}>Input Perolehan Suara</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Index