import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../../layouts/Header'
import { Accordion, Button, Card, Col, Container, Form, Image, ListGroup, ListGroupItem, Offcanvas, Row } from 'react-bootstrap'
import Footer from '../../../layouts/Footer'
import { notif_error, notif_success } from '../../../notif'
import { useStateAuthContext, useStateHostContext } from '../../../context'
import { HashLoader } from 'react-spinners'

const Index = () => {
  const { HOST } = useStateHostContext()
  const { STATE_AUTH } = useStateAuthContext()
  const location = useLocation()
  const navigate = useNavigate()
  const [param] = useState(location.state)
  const [loading, setLoading] = useState(true)
  const [dataCaleg, setDataCaleg] = useState([])
  const [totalsuara, setTotalsuara] = useState(0)
  const [totalSuaraTidakSah, setTotalSuaraTidakSah] = useState(null)
  const [aksesMenu, setAksesMenu] = useState(null)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleHide = () => setShow(false)

  const colorLoadingSet = (idJenis) => {
    switch (idJenis) {
      case 1:
        return "#555555"
      case 2:
        return "red"
      case 3:
        return "#a4d100"
      case 4:
        return "blue"
      case 5:
        return "green"
      default:
        break;
    }
  }

  const handleGetAksesMenu = useCallback(async () => {
    setLoading(true)
    try {
      const requ = await fetch(`${HOST}/api/v1/relawan/akses-menu/${STATE_AUTH?.id_relawan}`)
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          setAksesMenu(resu.data)
          switch (param?.id) {
            case 1:
              return resu?.data[0].final_pemilihan_1 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 2:
              return resu?.data[0].final_pemilihan_2 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 3:
              return resu?.data[0].final_pemilihan_3 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 4:
              return resu?.data[0].final_pemilihan_4 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 5:
              return resu?.data[0].final_pemilihan_5 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 6:
              return resu?.data[0].final_pemilihan_6 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 7:
              return resu?.data[0].final_pemilihan_7 === "1" ? setAksesMenu(true) : setAksesMenu(false)
            case 8:
              return resu?.data[0].final_pemilihan_8 === "1" ? setAksesMenu(true) : setAksesMenu(false)

            default:
              break;
          }
          setLoading(false)
        }
      } else {
        throw new Error(resu.message)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }, [STATE_AUTH, HOST, param?.id])

  const handleGetSuaraCalegPerJenis = useCallback(async (idJenisPemilihan) => {
    setLoading(true)
    setDataCaleg([])
    try {
      const requ = await fetch(`${HOST}/api/v1/suara/perhitungan/caleg/perjenis/${idJenisPemilihan}/${STATE_AUTH?.id_tps}`)
      const resu = await requ.json()
      if (resu.success) {
        setDataCaleg(resu.data)
        param?.id === 1 || param?.id === 2
          ?
          setTotalsuara(resu.data?.reduce((sum, { total_suara }) => Number(sum) + Number(total_suara), 0))
          :
          setTotalsuara(resu.data?.reduce((sum, { caleg }) => {
            return sum + caleg.reduce((acc, { total_suara }) => acc + Number(total_suara), 0);
          }, 0));

        setLoading(false)
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }, [HOST, STATE_AUTH, param?.id])

  const handleSubmitFinalisasi = async () => {

    if (totalSuaraTidakSah > (Number(STATE_AUTH?.dpt) + Number(STATE_AUTH?.dptb) + Number(STATE_AUTH?.dpk)) - totalsuara) {
      notif_error({ msg: "Suara tidak sah tidak boleh lebih dari total suara sisa" })
      return
    }

    setLoading(true)
    const data = new FormData()
    data.append('id_relawan', STATE_AUTH?.id_relawan)
    data.append('id_tps', STATE_AUTH?.id_tps)
    data.append('id_jenis_pemilihan', param?.id)
    data.append('suara_sah', totalsuara ? totalsuara : 0)
    data.append('suara_tidak_sah', totalSuaraTidakSah ? totalSuaraTidakSah : 0)
    try {
      const requ = await fetch(`${HOST}/api/v1/relawan/finalisasi-suara-tps`, {
        method: 'POST',
        body: data
      })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          notif_success({ msg: resu.message })
          handleHide()
          handleGetAksesMenu()
          handleGetSuaraCalegPerJenis(param?.id)
          setLoading(false)
        }
      } else {
        throw new Error({ msg: resu.message })
      }
    } catch (error) {
      notif_error({ msg: error.message })
      setLoading(false)
      handleHide()
    }
  }

  useEffect(() => {
    handleGetAksesMenu()
    handleGetSuaraCalegPerJenis(param?.id)
  }, [param.id, handleGetAksesMenu, handleGetSuaraCalegPerJenis])
  return (
    <>
      <Header menuUtama={false} judulHalaman={param?.name} linkBack={'/suara'} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <Row>
            <div className='col-12 col-sm-12 col-lg-12 mb-4'>
              <div className="card service-card card-bg-img bg-img  b-3" style={{ backgroundImage: `url(${require(`../../../assets/img/core-pemilu/${param?.img}`)})`, height: '100px' }}>
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <h5 className='text-white'>{param?.name}</h5>
                  </div>
                </div>
              </div>
              {
                !loading
                  ?
                  param.id === 1 || param.id === 2
                    ?
                    <ListGroup className='mt-2'>
                      {
                        dataCaleg?.map((item, index) => {
                          return (
                            <ListGroupItem key={index} className='d-flex justify-content-between align-items-center  bg-light mb-1'
                              onClick={() => {
                                aksesMenu
                                  ?
                                  navigate('/suara/perbaharui',
                                    {
                                      state: {
                                        idJenisPemilihan: param.id,
                                        namaCaleg: item.nama_caleg,
                                        noUrut: item.no_urut,
                                        noUrutPartai: null,
                                        idCaleg: item.id_caleg,
                                        foto: item.foto,
                                        totalSuara: item.total_suara,
                                        tglInput: item.tgl_input,
                                        suaraPartai: false,
                                        ...location.state
                                      }
                                    })
                                  :
                                  handleShow()
                              }}>
                              <div>
                                <span className="badge rounded-pill bg-secondary">{item.no_urut}</span>
                                <small className='mx-2'>{item.nama_caleg}</small>
                              </div>
                              <span>{item.total_suara}</span>
                            </ListGroupItem>
                          )
                        })
                      }
                    </ListGroup>
                    :
                    <Accordion defaultActiveKey="0" className='mt-2'>
                      {
                        dataCaleg?.map((val, ind) => {
                          return (
                            <Accordion.Item eventKey={ind} className='mb-1' key={ind} style={{ backgroundColor: `${val.warna}` }}>
                              <Accordion.Header className='d-flex flex-row justify-content-between'>
                                <Image src={`${HOST}${val.gambar}`} alt='image' width={30} className='me-2' />
                                <div>
                                  <span>{val.no_urut_partai}) {val.nama_partai}</span> <br />
                                  <small className='text-muted'>Lihat Daftar Caleg</small>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body className='p-2 m-0' style={{ backgroundColor: `${val.warna}` }}>
                                {
                                  val.caleg?.map((item, index) => {
                                    return (
                                      <ListGroupItem
                                        key={index}
                                        className='d-flex justify-content-between align-items-center bg-light mb-1'
                                        onClick={() => {
                                          aksesMenu
                                            ?
                                            navigate('/suara/perbaharui',
                                              {
                                                state: {
                                                  idJenisPemilihan: param.id,
                                                  namaCaleg: item.nama_caleg,
                                                  noUrut: item.no_urut,
                                                  noUrutPartai: val.no_urut_partai,
                                                  idCaleg: item.id_caleg,
                                                  foto: item.no_urut === "100" ? val.gambar : item.foto,
                                                  totalSuara: item.total_suara,
                                                  tglInput: item.tgl_input,
                                                  suaraPartai: false,
                                                  ...location.state
                                                }
                                              })
                                            :
                                            handleShow()
                                        }}>
                                        <div>
                                          <span className="badge rounded-pill bg-secondary">{item.no_urut === "100" ? <Image src={`${HOST}${val.gambar}`} alt='image' width={20} /> : item.no_urut}</span>
                                          <small className='mx-2'>{item.nama_caleg}</small>
                                        </div>
                                        <span>{item.total_suara}</span>
                                      </ListGroupItem>
                                    )
                                  })
                                }
                                {
                                  !val.caleg?.some(item => item.no_urut === "100")
                                    ?
                                    <ListGroupItem
                                      className='d-flex justify-content-between align-items-center bg-light mb-1'
                                      onClick={() => {
                                        aksesMenu ?
                                          navigate('/suara/perbaharui',
                                            {
                                              state: {
                                                idJenisPemilihan: param.id,
                                                idPartai: val.id_partai,
                                                namaCaleg: val.nama_partai,
                                                noUrut: val.no_urut_partai,
                                                noUrutPartai: val.no_urut_partai,
                                                foto: val.gambar,
                                                tglInput: null,
                                                suaraPartai: true,
                                                ...location.state
                                              }
                                            })
                                          :
                                          handleShow()
                                      }}>
                                      <div>
                                        <Image src={`${HOST}${val.gambar}`} alt='image' width={20} />
                                        <small className='mx-2'>Suara Partai</small>
                                      </div>
                                      <span>+</span>
                                    </ListGroupItem>
                                    :
                                    null
                                }

                              </Accordion.Body>
                            </Accordion.Item>
                          )
                        })
                      }
                    </Accordion>
                  :
                  <div className="d-flex align-items-center justify-content-center mt-5">
                    <HashLoader size={64} className='mt-5' color={colorLoadingSet(param.id)} />
                  </div>
              }
            </div>
          </Row>
          <Row>
            {
              !loading
                ?

                aksesMenu && totalsuara > 0 ?
                  <>
                    <Col md={12} lg={12} sm={12} >
                      <Card className='p-2'>
                        <ListGroup variant="flush">
                          <ListGroup.Item className='d-flex flex-column'>
                            <span className='text-muted'>Total suara belum diinput</span>
                            <small style={{ fontSize: '16px' }}>{(Number(STATE_AUTH?.dpt) + Number(STATE_AUTH?.dptb) + Number(STATE_AUTH?.dpk)) - totalsuara}</small>
                          </ListGroup.Item>
                          <ListGroup.Item className='d-flex flex-column'>
                            <span className='text-muted'>Total suara sah</span>
                            <small style={{ fontSize: '16px' }}>{totalsuara}</small>
                          </ListGroup.Item>
                          <ListGroup.Item className='d-flex flex-column'>
                            <span className='text-muted'>Total suara tidak sah</span>
                            <Form.Control
                              type='number'
                              placeholder='0'
                              value={totalSuaraTidakSah}
                              max={(Number(STATE_AUTH?.dpt) + Number(STATE_AUTH?.dptb) + Number(STATE_AUTH?.dpk)) - totalsuara}
                              onChange={(e) => setTotalSuaraTidakSah(e.target.value)}
                              disabled={(Number(STATE_AUTH?.dpt) + Number(STATE_AUTH?.dptb) + Number(STATE_AUTH?.dpk)) - totalsuara === 0} />
                          </ListGroup.Item>
                          <ListGroup.Item className='d-flex flex-column'>
                            <Button type='button' onClick={handleShow}>SELESAI PENGINPUTAN</Button>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Offcanvas show={show} onHide={handleHide} placement='bottom'>
                        <Offcanvas.Body className='m-0 pb-3'>
                          <Container>
                            <div className='text-center w-100'><span>Anda Yakin ? </span></div>
                            <p className='border p-2'>Pastikan data yang anda input sudah benar, setelah melakukan finalisasi, maka anda tidak dapat mengubah suara caleg/capres kembali</p>
                            <div className='d-flex flex-row justify-content-between'>
                              <Button className='btn btn-danger m-1' style={{ width: "200px" }} type='button' onClick={handleHide}>Tidak</Button>
                              <Button className='btn btn-info m-1' style={{ width: "200px" }} type='button' onClick={handleSubmitFinalisasi}>Ya</Button>
                            </div>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </Col>
                  </>

                  :
                  <Col md={12}>
                    <Offcanvas show={show} onHide={handleHide} placement='bottom'>
                      <Offcanvas.Body className='m-0 pb-3'>
                        <Container>
                          <div className='text-center w-100'><span>Tidak dapat mengubah suara ini ! </span></div>
                          <p className='border p-2'>Jenis pemilihan ini sudah difinaslisasi. untuk dapat mengubah suara silahkan hubungi admin</p>
                          <div className='d-flex flex-row justify-content-between'>
                            <Button className='btn btn-danger m-1' style={{ width: "100%" }} type='button' onClick={handleHide}>Keluar</Button>
                          </div>
                        </Container>
                      </Offcanvas.Body>
                    </Offcanvas>
                  </Col>
                :
                null
            }
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Index