import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useStateAuthContext, useStateHostContext } from '../../context'
import { notif_error } from '../../notif'
import { HashLoader } from 'react-spinners'

const Masuk = ({ page_title }) => {
  window.document.title = page_title
  const { HOST } = useStateHostContext();
  const { DISPATCH_AUTH } = useStateAuthContext();
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [typePassword, setTypePassword] = useState("password")
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);

    try {
      const requ = await fetch(`${HOST}/api/v1/relawan/check`, {
        method: 'POST',
        body: data
      })
      const resu = await requ.json()
      if (requ.ok) {
        if (resu.success) {
          DISPATCH_AUTH({ type: 'LOGIN', data: resu.data })
          setLoading(false)
        }
      } else {
        throw new Error(resu.message);
      }
    } catch (err) {
      notif_error({ msg: err.message })
      setLoading(false)
    }
  }

  return (
    <div className="login-wrapper d-flex align-items-center justify-content-center" style={{ backgroundColor: '#eaeaeb' }}>
      <div className="custom-container">
        <div className="text-center px-4">
          <img className="login-intro-img" src={require('../../assets/img/core-img/logo-smartrc.png')} alt="logo" />
        </div>

        <div className="register-form my-4">
          <h6 className="mb-3 text-center">Masuk ke Sistem</h6>
          <Form onSubmit={handleLogin}>
            <Form.Group className="my-3">
              <Form.Control type="text" id="username" placeholder="Username" autoComplete='off' value={username} onChange={(e) => setUsername(e.target.value)} required/>
            </Form.Group>

            <Form.Group className="position-relative mb-3">
              <Form.Control id="psw-input" type={typePassword} placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
              <div className="position-absolute" id="password-visibility">
                {typePassword === "password" ? <i className="bi bi-eye-fill" onClick={() => setTypePassword('text')}></i> : <i className="bi bi-eye-slash-fill" onClick={() => setTypePassword('password')}></i>}
              </div>
            </Form.Group>

            <Button className="btn btn-primary w-100" disabled={loading} type="submit">Masuk</Button>

            {
              loading && (
                <div className='position-relative'>
                  <div className="position-absolute top-50 start-50 translate-middle mt-5">
                    <HashLoader size={64} className='mt-5' color={'#0d6efd'} />
                  </div>
                </div>
              )
            }

          </Form>
        </div>
      </div>
    </div>
  )
}

export default Masuk