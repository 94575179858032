import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Pages from '../pages'
import { useStateAuthContext } from '../context'

const Index = () => {
  const { STATE_AUTH } = useStateAuthContext();

  const menu_relawan = (
    <>
      <Route path='/' element={<Pages page_access={'relawan'} page_name={'beranda'} page_data={null} page_title={'Beranda'} />}></Route>
      <Route path='/beranda' element={<Pages page_access={'relawan'} page_name={'beranda'} page_data={null} page_title={'Beranda'} />}></Route>
      <Route exact path='/suara' element={<Pages page_access={'relawan'} page_name={'suara'} page_data={null} page_title={'Suara'} />}></Route>
      <Route path='/suara/detail' element={<Pages page_access={'relawan'} page_name={'suara/detail'} page_data={null} page_title={'Detail Suara'} />}></Route>
      <Route path='/suara/perbaharui' element={<Pages page_access={'relawan'} page_name={'suara/perbaharui'} page_data={null} page_title={'Perbaharui Suara'} />}></Route>
      <Route path='/laporan' element={<Pages page_access={'relawan'} page_name={'laporan'} page_data={null} page_title={'Laporan'} />}></Route>
      <Route path='/profil' element={<Pages page_access={'relawan'} page_name={'profil'} page_data={null} page_title={'Profil'} />}></Route>
      <Route path='/versi' element={<Pages page_access={'relawan'} page_name={'versi'} page_data={null} page_title={'Versi'} />}></Route>
    </>
  )


  return (
    <Routes>
      {
        STATE_AUTH.is_login
          ?
          menu_relawan
          :
          <>
            <Route exact path='/' element={<Pages page_access={'relawan'} page_name={'masuk'} page_data={null} page_title={'Masuk ke Sistem'} />}></Route>
          </>
      }
      <Route path='/*' element={<Pages page_access={'relawan'} page_name={'nopage'} page_data={null} page_title={'Masuk ke Sistem'} />}></Route>
    </Routes>
  )
}

export default Index