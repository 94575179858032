function formatDate(inputDate) {
  // Membuat objek Date dari tanggal input
  let dateObj = new Date(inputDate);

  // Array hari dalam bahasa Inggris
  let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

  // Mendapatkan hari dari objek Date
  let day = days[dateObj.getDay()];

  // Mendapatkan tanggal, bulan, dan tahun dari objek Date
  let date = dateObj.getDate();
  let month = dateObj.getMonth() + 1; // Bulan dimulai dari 0 (Januari), maka perlu ditambah 1
  let year = dateObj.getFullYear();

  // Mendapatkan jam dan menit dari objek Date
  let hours = ('0' + dateObj.getHours()).slice(-2); // Menambahkan nol di depan jika jam kurang dari 10
  let minutes = ('0' + dateObj.getMinutes()).slice(-2); // Menambahkan nol di depan jika menit kurang dari 10

  // Membuat format tanggal yang diinginkan
  let formattedDate = `${day}, ${date}/${month}/${year}, ${hours}:${minutes}`;

  return formattedDate;
}

export { formatDate };