import React from 'react'
import Footer from '../../layouts/Footer'
import { Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Header from '../../layouts/Header'
import { useStateAuthContext } from '../../context'

const Suara = () => {
  const navigate = useNavigate();
  const { STATE_AUTH } = useStateAuthContext();

  const getIdDapil = (idDprdKota) => {
    switch (idDprdKota) {
      case "1":
        return 8
      case "2":
        return 5
      case "3":
        return 6
      case "4":
        return 7
      default:
        break;
    }
  }

  const getDapil = (idKec) => {
    switch (idKec) {
      case "1":
        return 'Dapil Kotamobagu Barat'
      case "3":
        return 'Dapil Kotamobagu Timur'
      case "2":
        return 'Dapil Kotamobagu Utara'
      case "4":
        return 'Dapil Kotamobagu Selatan'
      default:
        break;
    }
  }

  const [pemilihan] = React.useState([
    {
      id: 1,
      name: 'PRESIDEN RI',
      img: '1.jpg',
      status: 1,
    },
    {
      id: 2,
      name: 'DPD RI',
      img: '2.png',
      status: 0,
    },
    {
      id: 3,
      name: 'DPR RI',
      img: '3.png',
      status: 0,
    },
    {
      id: 4,
      name: 'DPRD PROVINSI',
      img: '4.png',
      status: 0,
    },
    {
      id: getIdDapil(STATE_AUTH.id_kec),
      name: getDapil(STATE_AUTH.id_kec),
      img: '5.png',
      status: 1,
    }
  ])

  return (
    <>
      <Header menuUtama={true} judulHalaman={'Suara'} linkBack={'/beranda'} />
      <div className="page-content-wrapper py-3">
        <div className="container">
          <Row>
            {
              pemilihan.map((item) => (
                item.id === 1
                  ?
                  <div className='col-12 col-sm-12 col-lg-12 mb-4' onClick={() => navigate(`/suara/detail`, { state: item })} style={{ cursor: 'pointer' }}>
                    <div className="card service-card card-bg-img bg-img  bg-overlay b-3" style={{ backgroundImage: `url(${require(`../../assets/img/core-pemilu/${item.img}`)})`, height: '150px' }}>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <h5 className='text-white'>{item.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className='col-6 col-sm-4 col-lg-3 mb-3' onClick={() => navigate(`/suara/detail`, { state: item })} style={{ cursor: 'pointer' }}>
                    <div className="card service-card card-bg-img bg-img  bg-overlay b-3" style={{ backgroundImage: `url(${require(`../../assets/img/core-pemilu/${item.img}`)})`, height: '150px' }}>
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <div className="text-center">
                          <h5 className='text-white'>{item.name}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
              ))
            }
          </Row>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Suara