import React from 'react'
import BERANDA from './beranda';
import SUARA from './suara';
import SUARA_DETAIL from './suara/detail';
import SUARA_PERBAHARUI from './suara/perbaharui';
import LAPORAN from './laporan';
import PROFIL from './profil';
import VERSI from './version';
import MASUK from './masuk';
import HALAMAN_KOSONG from './nopage';

const Index = ({ page_access, page_name, page_data, page_title }) => {
  let halaman = null;
  switch (page_access) {
    case 'relawan':
      switch (page_name) {
        case 'masuk':
          halaman = <MASUK page_data={page_data} page_title={page_title} />
          break;
        case 'beranda':
          halaman = <BERANDA page_data={page_data} page_title={page_title} />
          break;
        case 'suara':
          halaman = <SUARA page_data={page_data} page_title={page_title} />
          break;
        case 'suara/detail':
          halaman = <SUARA_DETAIL page_data={page_data} page_title={page_title} />
          break;
        case 'suara/perbaharui':
          halaman = <SUARA_PERBAHARUI page_data={page_data} page_title={page_title} />
          break;
        case 'laporan':
          halaman = <LAPORAN page_data={page_data} page_title={page_title} />
          break;
        case 'profil':
          halaman = <PROFIL page_data={page_data} page_title={page_title} />
          break;
        case 'versi':
          halaman = <VERSI page_data={page_data} page_title={page_title} />
          break;
        case 'nopage':
          halaman = <HALAMAN_KOSONG page_data={page_data} page_title={page_title} />
          break;
        default:
      }
      break;
    default:
      break;
  }

  return (
    <>
      {halaman}
    </>
  )
}

export default Index